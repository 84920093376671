<template>
	<svg
		role="img"
		class="icon"
		:width="width"
		:height="height"
		:viewBox="`0 0 ${width} ${height}`"
	>
		<path
			v-for="(path, name) in paths"
			:key="name"
			:class="`icon__${name}`"
			:d="path"
		/>
	</svg>
</template>

<script>
import icons from '../utilities/icons';

export default {
	props: {
		type: {
			type: String,
			required: true,
		},
	},
	data() {
		const data = icons[this.type] || {};

		return {
			width: data.width || data.size || 16,
			height: data.height || data.size || 16,
			paths: data.paths || [],
		};
	},
};
</script>
