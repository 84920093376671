<template>
	<BaseModal
		name="share"
		:class="['sharescreen', 'is-level-' + (level.index + 1)]"
	>
		<h2>{{ $l10n('share-level') }}</h2>

		<div class="sharescreen__poster">
			<StarGraphic :number="level.index + 1" class="sharescreen__star" />
			<div class="sharescreen__poster-frame">
				<div
					class="sharescreen__graphic"
					:style="{
						'background-image': `url(${$url(level.graphic)})`,
					}"
				/>
			</div>
		</div>

		<div class="sharescreen__info">
			<p v-content="text" />

			<ShareButtonGroup :link="link" />
		</div>
	</BaseModal>
</template>

<script>
import { mapState } from 'vuex';

export default {
	props: {
		level: {
			type: Object,
			required: true,
		},
		text: {
			type: String,
			required: true,
		},
	},
	computed: {
		...mapState(['id', 'language']),

		link() {
			let page = `survey-${this.id}-${this.language}`;
			if (this.level) {
				page += `-${this.level.id}`;
			}

			return page;
		},
	},
};
</script>
