<template>
	<BaseDraggable
		use-holsters
		:is-active="isActive"
		:is-repeat="isRepeat"
		:hands="hands"
		:timer="timer"
		@select="handleResult"
	/>
</template>

<script>
const TYPES = ['left', 'right'];

export default {
	inheritAttrs: false,
	props: {
		isActive: {
			type: Boolean,
			required: true,
		},
		isRepeat: Boolean,
		values: {
			type: Array,
			required: true,
		},
		labels: {
			type: Array,
			required: true,
		},
		colors: {
			type: Array,
			required: true,
		},
		timer: {
			type: Number,
			default: 0,
		},
	},
	data() {
		const hands = [];
		const optionCount = Math.min(
			this.values.length,
			this.labels.length,
			TYPES.length
		);

		for (let i = 0; i < optionCount; i++) {
			hands.push({
				type: TYPES[i],
				color: this.colors[i],
				value: this.values[i],
				label: this.labels[i],
			});
		}

		return {
			hands,
		};
	},
	methods: {
		handleResult(result) {
			this.$emit('answer', result);
		},
	},
};
</script>
