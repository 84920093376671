<template>
	<BaseModal name="levels" :body="false">
		<div class="carousel">
			<div
				ref="slides"
				class="carousel__inner"
				:style="{ width: levels.length * slideWidth + 'px' }"
				@keyup.left="prevSlide"
				@keyup.right="nextSlide"
			>
				<div
					v-for="(level, i) in levels"
					:key="level.score"
					:class="{
						carousel__slide: true,
						'is-active': i === currentSlide,
					}"
					@click="goToSlide(i)"
				>
					<LevelCard
						:number="i + 1"
						:name="level.title || level.name"
						:image-url="level.graphic"
						:download-url="level.reward"
						:is-unlocked="i <= current"
						:is-latest="i === current"
					/>
				</div>
			</div>
		</div>
	</BaseModal>
</template>

<script>
import { gsap } from 'gsap';
import Draggable from 'gsap/Draggable';

gsap.registerPlugin(Draggable);

import useResponsiveValue from '../utilities/responsive-value';

export default {
	props: {
		levels: {
			type: Array,
			required: true,
		},
		current: {
			type: Number,
			required: true,
		},
	},
	data() {
		return {
			slideWidth: 316,
			currentSlide: 0,
		};
	},
	watch: {
		current() {
			this.setPosition();
		},
	},
	mounted() {
		const carousel = this;
		const slides = this.$refs.slides;

		this.setPosition();

		const [drag] = Draggable.create(slides, {
			type: 'x',
			onDragEnd() {
				let offset = Math.round(this.endX / carousel.slideWidth);
				const levelCount = carousel.levels.length;

				// Cap it to between first and last item in slides wrapper
				offset = Math.min(0, offset);
				offset = Math.max(-1 * (levelCount - 1), offset);

				carousel.goToSlide(offset * -1);
			},
		});

		this.draggable = drag;

		this.handleSlideWidth();
	},
	methods: {
		handleSlideWidth() {
			useResponsiveValue(
				[
					{ query: '(max-height: 480px)', value: 260 },
					{ query: '(min-height: 481px) and (max-height: 560px)', value: 280 },
					{ query: '(min-height: 561px)', value: 300 },
				],
				newWidth => {
					this.slideWidth = newWidth + 16;
					this.setPosition();
				}
			);
		},

		setPosition() {
			gsap.set(this.$refs.slides, {
				x: this.slideWidth * this.current * -1,
			});

			this.currentSlide = this.current;
		},
		goToSlide(index) {
			const slides = this.$refs.slides;
			const slideWidth = this.slideWidth;

			this.draggable.disable();
			gsap.to(slides, {
				x: index * slideWidth * -1,
				ease: 'power2.out',
				duration: 0.4,
				onComplete: () => {
					this.draggable.enable().update();
				},
			});

			this.currentSlide = index;
		},
		nextSlide() {
			this.goToSlide(this.currentSlide + 1);
		},
		prevSlide() {
			this.goToSlide(this.currentSlide - 1);
		},
	},
};
</script>
