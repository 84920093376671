<template>
	<div class="option-question">
		<div class="option-question__buttons">
			<BaseButton
				v-for="option in options"
				:key="option.value"
				is-primary
				:color="option.color"
				@click="$emit('answer', option.value)"
				>{{ option.label }}</BaseButton
			>
		</div>
	</div>
</template>

<script>
export default {
	inheritAttrs: false,
	props: {
		values: {
			type: Array,
			required: true,
		},
		labels: {
			type: Array,
			required: true,
		},
		colors: {
			type: Array,
			required: true,
		},
		timer: {
			type: Number,
			default: 0,
		},
	},
	data() {
		const options = [];
		const optionCount = Math.min(
			this.values.length,
			this.labels.length,
			this.colors.length
		);

		for (let i = 0; i < optionCount; i++) {
			options.push({
				color: this.colors[i],
				value: this.values[i],
				label: this.labels[i],
			});
		}

		return {
			options,
		};
	},
};
</script>
