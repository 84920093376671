var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.tag,_vm._b({tag:"Component",class:{
		button: !_vm.isPlain,

		'is-primary': _vm.isPrimary,
		'is-secondary': _vm.isSecondary,
		'is-tertiary': _vm.isTertiary,
		'is-quaternary': _vm.isQuaternary,
		'is-link': _vm.isLink,

		'is-large': _vm.isLarge,
		'is-rounded': _vm.isRounded,
		'is-circular': _vm.isCircular,
		'is-plain': _vm.isPlain,

		['is-color-' + _vm.color]: _vm.color,

		'with-icon': !!_vm.icon,
		'with-icon-and-text': _vm.iconWithText,
	},attrs:{"type":_vm.tag === 'button' ? _vm.type : null,"tabindex":_vm.isParentActive ? null : -1},on:{"click":_vm.onClick}},'Component',_vm.attrs,false),[(_vm.icon)?_c('div',{staticClass:"button__inner"},[_c('BaseIcon',{attrs:{"type":_vm.icon}}),(_vm.iconWithText)?_vm._t("default"):_c('ScreenReaderText',[_vm._t("default")],2)],2):(!_vm.isPlain && !_vm.isLink)?_c('div',{staticClass:"button__inner"},[_vm._t("default")],2):_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }