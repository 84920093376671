<template>
	<BaseModal :name="id" class="interrupt" is-bubble @toggle="toggleActive">
		<div v-if="!!control">
			<h2 class="interrupt__title">{{ title }}</h2>
			<div v-content="text" />

			<div class="interrupt__options">
				<BaseButton
					v-for="option in options"
					:key="option.value"
					is-primary
					:color="option.color"
					@click="logAnswer(option.value)"
					>{{ option.label }}</BaseButton
				>
			</div>

			<BaseButton class="interrupt__skip" @click="logAnswer('abstain')">{{
				$l10n('skip')
			}}</BaseButton>
		</div>
	</BaseModal>
</template>

<script>
const COLORS = ['green', 'red'];

export default {
	props: {
		id: {
			type: String,
			default: 'interrupt',
		},
		name: {
			type: String,
			required: true,
		},
		title: {
			type: String,
			required: true,
		},
		text: {
			type: String,
			required: true,
		},
		control: {
			type: Object,
			required: true,
		},
	},
	computed: {
		options() {
			const control = this.control;

			const options = [];
			const optionCount = Math.min(
				control.values.length,
				control.labels.length,
				COLORS.length
			);

			for (let i = 0; i < optionCount; i++) {
				options.push({
					color: COLORS[i],
					value: control.values[i],
					label: control.labels[i],
				});
			}

			return options;
		},
	},
	methods: {
		toggleActive(isActive) {
			if (isActive) {
				this.startTime = Date.now();
			}
		},

		logAnswer(answer) {
			const timeSpent = Date.now() - this.startTime;

			this.$log.answer({
				question_type: this.control.name,
				question_name: this.name,
				answer_text: answer,
				duration_in_seconds: Math.round(timeSpent / 1000),
			});

			this.hideModal(this.id);
			this.$emit('done');
		},
	},
};
</script>
