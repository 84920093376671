<template>
	<BaseButton
		name="byline"
		location="main"
		class="byline"
		is-quaternary
		@click="showModal('about')"
	>
		<BaseIcon type="info" />
		{{ text }}
	</BaseButton>
</template>

<script>
export default {
	props: {
		text: {
			type: String,
			required: true,
		},
	},
};
</script>
