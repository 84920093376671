<template>
	<BaseScreen :name="id" class="demosurvey">
		<div class="demosurvey__header">
			<p>{{ progress_heading }}</p>
		</div>
		<div class="demosurvey__body">
			<h3 class="demosurvey__title">{{ title }}</h3>
			<p v-if="multiple_choice">{{ $l10n('multiple-choice') }}</p>
			<div
				:class="{
					demosurvey__buttons: true,
					'has-layout-grid': answers.length <= 8,
					'has-layout-fluid': answers.length > 8,
				}"
			>
				<BaseButton
					v-for="answer in answers"
					:key="answer.id"
					:class="{ 'is-selected': hasAnswer(answer.name) }"
					@click="toggleAnswer(answer.name)"
					>{{ answer.text }}</BaseButton
				>
			</div>
		</div>
		<div class="demosurvey__footer">
			<BaseButton
				is-primary
				:disabled="selected.length === 0"
				@click="logAnswer()"
				>{{ $l10n('submit') }}</BaseButton
			>

			<BaseButton is-link @click="logAnswer('abstain')">{{
				$l10n('wont-disclose')
			}}</BaseButton>
		</div>
	</BaseScreen>
</template>

<script>
export default {
	props: {
		id: {
			type: String,
			required: true,
		},
		name: {
			type: String,
			required: true,
		},
		title: {
			type: String,
			required: true,
		},
		answers: {
			type: Array,
			required: true,
		},
		multiple_choice: Boolean,
		heading: {
			type: String,
			required: true,
		},
		index: {
			type: Number,
			required: true,
		},
		total: {
			type: Number,
			required: true,
		},
	},
	data() {
		const progress_heading = this.heading
			.replace('%current%', this.$l10n(this.index + 1))
			.replace('%total%', this.$l10n(this.total));

		return {
			progress_heading,
			selected: [],
		};
	},
	methods: {
		toggleAnswer(answer) {
			const selected = [...this.selected];
			const index = selected.indexOf(answer);

			// Remove answer if already selected
			if (index >= 0) {
				selected.splice(index, 1);
				this.selected = selected;
				return;
			}

			// Otherwise, add/replace depending on multiple_choice
			if (this.multiple_choice) {
				this.selected.push(answer);
			} else {
				this.selected = [answer];
			}
		},
		hasAnswer(answer) {
			return this.selected.includes(answer);
		},
		logAnswer(answer) {
			answer = answer || this.selected;

			if (!(answer instanceof Array)) {
				answer = [answer];
			}

			this.$log.demographic({
				question_name: this.name,
				answers: answer,
			});

			this.$emit('next');
		},
	},
};
</script>
