<template>
	<BaseButton
		:name="useAudio ? 'mute' : 'unmute'"
		location="main"
		:class="{
			soundtoggle: true,
			'is-muted': !useAudio,
		}"
		is-secondary
		@click="toggleAudio"
	>
		<BaseIcon type="sound" class="soundtoggle__icon" />

		<ScreenReaderText v-if="useAudio">{{ $l10n('mute') }}</ScreenReaderText>
		<ScreenReaderText v-else>{{ $l10n('unmute') }}</ScreenReaderText>
	</BaseButton>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
	computed: {
		...mapState(['useAudio']),
	},
	methods: mapMutations(['toggleAudio']),
};
</script>
