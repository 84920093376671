var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseScreen',{class:{
		levelscreen: true,
		'is-change-up': _vm.isLevelUp,
		'is-change-down': !_vm.isLevelUp,
		['is-level-' + (_vm.level.index + 1)]: true,
		'show-recovery': _vm.showRecover,
	},attrs:{"name":"levelChange"},on:{"toggle":_vm.toggleActive}},[_c('transition',{attrs:{"mode":"out-in"}},[(!_vm.showRecover)?_c('div',{key:"change",staticClass:"levelscreen__header"},[_c('StarGraphic',{staticClass:"levelscreen__star levelscreen__star--change",attrs:{"number":_vm.level.index + 1}}),_c('h2',[_vm._v(_vm._s(_vm.title))]),_c('p',{directives:[{name:"content",rawName:"v-content",value:(_vm.text),expression:"text"}]})],1):(!_vm.isLevelUp && _vm.offerRecover && _vm.showRecover)?_c('div',{key:"recover",staticClass:"levelscreen__header levelscreen__header--recover"},[_c('p',[_vm._v(_vm._s(_vm.$l10n('offer-recovery')))]),_c('StarGraphic',{staticClass:"levelscreen__star levelscreen__star--recover"},[_c('span',{staticClass:"star__label star__label--before"},[_vm._v(_vm._s(_vm.$l10n(_vm.level.index + 1)))]),_c('span',{staticClass:"star__label star__label--after"},[_vm._v(_vm._s(_vm.$l10n(_vm.level.index + 2)))])]),_c('BaseButton',{attrs:{"name":"recover","location":_vm.level.name,"is-primary":"","is-rounded":""},on:{"click":function($event){return _vm.$emit('recover')}}},[_vm._v(_vm._s(_vm.$l10n('recover-level')))]),_c('BaseButton',{staticClass:"levelscreen__skip",attrs:{"name":"skip-recover","location":_vm.level.name,"is-plain":""},on:{"click":function($event){return _vm.$emit('done')}}},[_vm._v(_vm._s(_vm.$l10n('skip')))])],1):_vm._e()]),_c('div',{staticClass:"levelscreen__graphic",style:({
			'background-image': `url(${_vm.$url(_vm.level.graphic)})`,
		})}),_c('div',{staticClass:"levelscreen__footer"},[_c('div',{staticClass:"levelscreen__buttons"},[(_vm.isLevelUp || _vm.didRecover)?_c('BaseButton',{attrs:{"name":"share","location":_vm.level.name,"is-secondary":""},on:{"click":function($event){return _vm.showModal('share')}}},[_vm._v(_vm._s(_vm.$l10n('share-level')))]):_vm._e()],1),_c('BaseButton',{staticClass:"levelscreen__continue",attrs:{"name":"continue","location":_vm.level.name,"icon":"next","is-primary":"","is-circular":""},on:{"click":_vm.maybeOfferRecovery}},[_vm._v(_vm._s(_vm.$l10n('continue')))])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }