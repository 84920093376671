<template>
	<BaseButton
		v-if="languages.length > 1"
		name="languages"
		location="main"
		class="langtoggle"
		is-quaternary
		@click="showModal('languages')"
	>
		<BaseIcon type="globe" />
		{{ currentLanguage.slug }}

		<Portal to="home">
			<BaseModal name="languages" is-mini>
				<div class="language-picker">
					<h2>{{ $l10n('select-language') }}</h2>

					<a
						v-for="language in languages"
						:key="language.slug"
						:class="{
							'language-picker__option': true,
							'is-current': currentLanguage === language,
						}"
						:href="addQueryArg('lang', language.slug)"
						>{{ language.name }}</a
					>
				</div>
			</BaseModal>
		</Portal>
	</BaseButton>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
	computed: {
		...mapState(['languages']),
		...mapGetters({
			currentLanguage: 'getLanguage',
		}),
	},
	methods: {
		addQueryArg(name, value) {
			const params = new URLSearchParams(window.location.search);

			params.set(name, value);

			return '?' + params.toString();
		},
	},
};
</script>
