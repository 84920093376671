<template>
	<BaseScreen name="home" class="homescreen">
		<div class="homescreen__hand homescreen__hand--thumbup"></div>
		<div class="homescreen__hand homescreen__hand--thumbdown"></div>

		<div class="homescreen__header">
			<TheHomeScreenGraphic />
			<h1 v-content="title" class="homescreen__title" />
			<p v-if="tagline" v-content="tagline" class="homescreen__tagline" />
		</div>

		<div class="homescreen__body">
			<BaseButton
				name="start"
				location="home"
				is-primary
				is-large
				@click="$emit('next')"
				>{{ buttonText || $l10n('start') }}</BaseButton
			>
		</div>
	</BaseScreen>
</template>

<script>
export default {
	props: {
		title: {
			type: String,
			required: true,
		},
		tagline: {
			type: String,
			default: null,
		},
		buttonText: {
			type: String,
			default: null,
		},
	},
};
</script>
