<template>
	<BaseScreen v-if="text" name="recoveryNotice" class="noticescreen">
		<div class="noticescreen__body">
			<p v-content="text"></p>

			<BaseButton
				name="continue"
				:location="name"
				is-primary
				@click="$emit('done')"
				>{{ $l10n('continue') }}</BaseButton
			>
		</div>
	</BaseScreen>
</template>

<script>
export default {
	props: {
		name: {
			type: String,
			required: true,
		},
		text: {
			type: String,
			required: true,
		},
	},
};
</script>
