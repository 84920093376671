var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
		'draggable-hand': true,
		[this.type]: true,
		[`is-color-${_vm.color}`]: true,
		animating: _vm.isAnimating,
		dragging: _vm.isDragging,
		'in-range': _vm.isInRange,
	}},[(_vm.withHolster)?_c('BaseButton',{staticClass:"draggable-hand__holster",attrs:{"is-plain":""},on:{"click":_vm.select}},[_c('div',{staticClass:"draggable-hand__holster__label"},[_vm._v(_vm._s(_vm.label))])]):_vm._e(),_c('div',{ref:"handle",staticClass:"draggable-hand__handle"},[(!_vm.withHolster)?_c('BaseButton',{staticClass:"draggable-hand__handle__label",attrs:{"is-plain":""},on:{"click":_vm.select}},[_vm._v(_vm._s(_vm.label))]):_vm._e(),_c('HandGraphic',{attrs:{"color":_vm.color,"is-open":_vm.isInRange}})],1),_c('svg',{ref:"arm",staticClass:"draggable-hand__arm"},[_c('path',{staticClass:"draggable-hand__arm__stroke",attrs:{"d":_vm.armPath}}),_c('path',{staticClass:"draggable-hand__arm__fill",attrs:{"d":_vm.armPath}})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }