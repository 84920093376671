<template>
	<div
		id="app"
		:class="{
			app: true,
			'in-game': inGame,
			'is-done': atEnd,
		}"
	>
		<TheAppToolbar
			:in-game="inGame"
			:byline="byline"
			:privacy-policy-url="privacy_policy_url"
			:terms-of-use-url="terms_of_use_url"
		/>

		<TheGameStatus
			v-if="inGame"
			:level="theLevel"
			:total-stars="pointsUntilNextLevel"
			:filled-stars="pointsSinceLastLevel"
			:show-stars="!atEnd"
		/>

		<TheHomeScreen
			:title="title"
			:tagline="tagline"
			:button-text="start_text"
			@next="startGame"
		/>

		<MainQuestionScreen
			v-for="question in mainQuestions"
			:key="question.id"
			v-bind="question"
			class="is-type-main"
			use-hands
			@answer="maybeAddRepeatQuestion"
			@done="nextMainQuestion"
		/>

		<RecoveryQuestionScreen
			v-for="question in recoveryQuestions"
			:key="question.id"
			v-bind="question"
			class="is-type-recovery"
			use-hands
			@done="nextRecoveryQuestion"
		/>

		<InterruptQuestionScreen
			v-for="question in interruptQuestions"
			:key="question.id"
			v-bind="question"
			class="is-type-interrupt"
			skippable
			@done="nextMainQuestion"
		/>

		<TheDemographicSurvey
			v-if="demographic_survey"
			v-bind="demographic_survey"
			@done="goToScreen('end')"
		/>

		<TheEndScreen :level="theLevel" :text="share_text" @restart="restart" />

		<TheLevelScreen
			:level="theLevel"
			:offer-recover="canDoRecovery"
			:did-recover="didRecoverLevel"
			:recover-notice="recovery_notice"
			@recover="startRecoveryQuestions"
			@done="nextMainQuestion"
		/>

		<TheRecoveryNoticeScreen
			name="recoveryNotice"
			:text="recovery_notice"
			@done="startRecoveryQuestions"
		/>

		<TheLevelsModal :levels="levels" :current="level" />

		<TheShareScreen :level="theLevel" :text="share_text" />

		<PortalTarget :name="currentScreen" />

		<TheAboutModal :content="about_html" />

		<TheLandscapeNotice v-if="inGame && isMobileLandscape" />
	</div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { Howl } from 'howler';
import shuffleArray from 'shuffle-array';

function shuffle(array) {
	return shuffleArray(array, { copy: true });
}

const pickRandom = shuffleArray.pick;

export default {
	name: 'App',
	props: {
		title: {
			type: String,
			required: true,
		},
		tagline: {
			type: String,
			default: null,
		},
		byline: {
			type: String,
			required: true,
		},
		start_text: {
			type: String,
			required: true,
		},
		about_html: {
			type: String,
			required: true,
		},
		share_text: {
			type: String,
			required: true,
		},
		main_questions: {
			type: Array,
			required: true,
		},
		followup_interfaces: {
			type: Array,
			required: true,
		},
		recovery_questions: {
			type: Array,
			required: true,
		},
		interrupt_questions: {
			type: Array,
			required: true,
		},
		demographic_survey: {
			type: Object,
			default: null,
		},
		levels: {
			type: Array,
			required: true,
		},
		recovery_notice: {
			type: String,
			default: null,
		},
		privacy_policy_url: {
			type: String,
			default: null,
		},
		terms_of_use_url: {
			type: String,
			default: null,
		},

		shuffle_main_questions: Boolean,
		shuffle_recovery_questions: Boolean,
		shuffle_interrupt_questions: Boolean,
		leading_main_questions: {
			type: Number,
			default: null,
		},
		repeat_first_wrong_question: {
			type: Number,
			default: 0,
		},
		reward_correct_repeat_questions: Boolean,
		points_per_level: {
			type: [Number, Array],
			default: 2,
		},
		interrupt_placement: {
			type: [Number, Array],
			default: 5,
		},

		music_source: {
			type: String,
			default: null,
		},

		show_uuid: {
			type: [Boolean, Number],
			default: false,
		},
	},
	data() {
		let pointsPerLevel = this.points_per_level;
		if (pointsPerLevel instanceof Array) {
			pointsPerLevel = pointsPerLevel.map(v => parseInt(v || 0));
		}

		let interruptPlacement = this.interrupt_placement;
		if (interruptPlacement instanceof Array) {
			interruptPlacement = interruptPlacement.map(v => parseInt(v || 0));
		}

		return {
			mainQuestions: [],
			recoveryQuestions: [],
			allRecoveryQuestions: [],
			interruptQuestions: null,

			pointsPerLevel,
			interruptPlacement,

			currentMainQuestion: 0,
			currentRecoveryQuestion: 0,
			currentInterruptQuestion: 0,
			didRepeatQuestion: false,
			didInterruptQuestion: false,

			currentLevel: 0,
			didRecoverLevel: false,
			didRecoveryNotice: false,

			atEnd: false,
			ledWithInterrupt: false,
		};
	},
	computed: {
		...mapState([
			'currentScreen',
			'answers',
			'score',
			'isMobileLandscape',
			'useAudio',
		]),

		theLevels() {
			const perLevel = this.pointsPerLevel;

			return this.levels.map((level, i) => {
				if (typeof perLevel === 'number') {
					level.score = i * perLevel;
				} else {
					// First level is always score 0, so shift i back one
					level.score = perLevel[i - 1] || 0;
				}

				return level;
			});
		},
		level() {
			// Find the current level based on score
			let level = 0;
			for (let i = 0; i < this.theLevels.length; i++) {
				if (this.score >= this.theLevels[i].score) {
					level = i;
				}
			}

			return level;
		},
		theLevel() {
			return this.theLevels[this.currentLevel];
		},
		pointsUntilNextLevel() {
			// Get the next level
			const nextLevel = this.theLevels[this.currentLevel + 1];

			// If none, return 0
			if (!nextLevel) {
				return 0;
			}

			// Return the points needed for next level
			return nextLevel.score - this.theLevel.score;
		},
		pointsSinceLastLevel() {
			return this.score - this.theLevel.score;
		},

		inGame() {
			return this.currentScreen !== 'home';
		},

		canDoRecovery() {
			return (
				this.allRecoveryQuestions.length > 0 ||
				this.followup_interfaces.length > 0
			);
		},

		shouldShowInterruptQuestion() {
			// Not if we have no placement rules
			if (!this.interruptPlacement) {
				return;
			}

			// Not if we have no more left
			if (this.currentInterruptQuestion >= this.interruptQuestions.length) {
				return false;
			}

			// If interrupt_placement is interval, check that
			if (typeof this.interruptPlacement === 'number') {
				return (this.currentMainQuestion + 1) % this.interruptPlacement === 0;
			}

			// Otherwise check if we're on the next placement
			return (
				this.currentMainQuestion + 1 ===
				this.interruptPlacement[this.currentInterruptQuestion]
			);
		},
	},
	watch: {
		useAudio() {
			if ( ! this.music && this.music_source ) {
				this.music = new Howl( {
				src: [ this.music_source ],
				loop: true,
				} );
			}

			if (this.music && !this.music.playing()) {
				this.music.play();
			}
		},
	},
	created() {
		this.setup();
	},
	mounted() {
		const mediaQuery = window.matchMedia(
			'(orientation: landscape) and (max-height:500px)'
		);

		const checkMobileLandscape = query => {
			this.setMobileLandscape(query.matches);
		};

		mediaQuery.addEventListener('change', checkMobileLandscape);
		checkMobileLandscape(mediaQuery);
	},
	methods: {
		...mapMutations(['goToScreen', 'incrementScore']),

		...mapMutations({
			resetState: 'reset',
			setMobileLandscape: 'setMobileLandscape',
		}),

		setup() {
			this.goToScreen('home');

			if (this.shuffle_main_questions) {
				if (this.leading_main_questions) {
					this.mainQuestions = shuffle(
						this.main_questions.slice(0, this.leading_main_questions)
					).concat(
						shuffle(this.main_questions.slice(this.leading_main_questions))
					);
				} else {
					this.mainQuestions = shuffle(this.main_questions);
				}
			} else {
				this.mainQuestions = [...this.main_questions];
			}

			if (this.shuffle_recovery_questions) {
				this.allRecoveryQuestions = shuffle(this.recovery_questions);
			} else {
				this.allRecoveryQuestions = [...this.recovery_questions];
			}

			if (this.shuffle_interrupt_questions) {
				this.interruptQuestions = shuffle(this.interrupt_questions);
			} else {
				this.interruptQuestions = [...this.interrupt_questions];
			}
		},

		goToMainQuestion(index) {
			if (typeof index === 'number' && index < this.mainQuestions.length) {
				this.currentMainQuestion = index;
				this.goToScreen(this.mainQuestions[index].id);
			} else {
				this.currentMainQuestion = null;

				this.atEnd = true;
				if (this.demographic_survey) {
					this.goToScreen('demosurvey');
				} else {
					this.goToScreen('end');
				}
			}
		},
		nextMainQuestion() {
			// If level changed, short circuit and go to
			// LevelChange screen instead, next call will
			// proceed normally
			if (this.currentLevel !== this.level) {
				this.didRecoverLevel = false;
				this.logRankChange();
				this.goToScreen('levelChange');
				this.currentLevel = this.level;
				return;
			}

			// If on the interrupt interval, short circuit and
			// setup an interrupt question, next call will unset
			// it and proceed normally
			if (this.shouldShowInterruptQuestion && !this.didInterruptQuestion) {
				this.nextInterruptQuestion();
				return;
			} else {
				this.didInterruptQuestion = false;
			}
			if (this.ledWithInterrupt) {
				// this is simply to see if our first question in game was an interrupt
				// if so, we need to go to the real zeroth main question, not the after
				this.goToMainQuestion(0);
				// this is a single fire operation, so reset flag
				this.ledWithInterrupt = false;
				return;
			}

			this.goToMainQuestion(this.currentMainQuestion + 1);
		},

		maybeAddRepeatQuestion(result) {
			// If not a wrong answer, or a repeat is not supported, or was already done, skip
			if (
				result !== false ||
				!this.repeat_first_wrong_question ||
				this.didRepeatQuestion
			) {
				return;
			}

			// Determine the position the repeat would go
			const repeatPosition =
				this.currentMainQuestion + this.repeat_first_wrong_question;

			// If there's enough space, insert a copy, as a repeat
			if (repeatPosition < this.mainQuestions.length) {
				const redoQuestion = {
					...this.mainQuestions[this.currentMainQuestion],
					id: 'repeat',
					isRepeat: true,
					affectsScore: this.reward_correct_repeat_questions,
				};

				// Insert this repeated question 5 entries ahead
				this.mainQuestions.splice(repeatPosition, 0, redoQuestion);

				// Mark as having added a repeat
				this.didRepeatQuestion = true;
			}
		},

		startRecoveryQuestions() {
			if (!this.didRecoveryNotice && this.recovery_notice) {
				this.goToScreen('recoveryNotice');
				this.didRecoveryNotice = true;
				return;
			}

			this.recoveryQuestions = [];

			// If followup questions are available, create one for the previous question
			if (this.followup_interfaces.length > 0) {
				// Get the previous question + a random followup interface
				const currentMainQuestion =
					this.mainQuestions[this.currentMainQuestion];
				const { id: followupId, ...followupConfig } = pickRandom(
					this.followup_interfaces
				);

				// Create/add this followup question
				this.recoveryQuestions.push({
					id: currentMainQuestion.id + followupId,
					name: currentMainQuestion.name,
					title: followupConfig.title,
					text: currentMainQuestion.correct_text,
					control: followupConfig,
				});
			}

			// Add the next general recovery question if any are left
			if (this.allRecoveryQuestions.length > 0) {
				this.recoveryQuestions.push(this.allRecoveryQuestions.shift());
			}

			// Go to the first recovery question
			setTimeout(() => this.goToRecoveryQuestion(0));
		},
		goToRecoveryQuestion(index) {
			if (typeof index === 'number' && index < this.recoveryQuestions.length) {
				this.currentRecoveryQuestion = index;
				this.goToScreen(this.recoveryQuestions[index].id);
			} else {
				this.currentRecoveryQuestion = null;

				// Completed, re-increment their score
				this.incrementScore();
				this.logRankChange('recover'), (this.currentLevel = this.level);
				this.didRecoverLevel = true;
				this.goToScreen('levelChange');
			}
		},
		nextRecoveryQuestion() {
			this.goToRecoveryQuestion(this.currentRecoveryQuestion + 1);
		},

		nextInterruptQuestion() {
			this.goToScreen(
				this.interruptQuestions[this.currentInterruptQuestion].id
			);
			this.currentInterruptQuestion++;
			this.didInterruptQuestion = true;
		},

		logRankChange(type) {
			if (!type) {
				type = this.level > this.currentLevel ? 'up' : 'down';
			}

			// Log the rank change
			this.$log.event({
				event_type: 'rank_' + type,
				target: String(this.level + 1),
				question_name: this.mainQuestions[this.currentMainQuestion].name,
			});
		},

		startGame() {
			// if our interrupt array has a lead zero, show that screen first no matter what
			if (this.interruptPlacement[0] === 0) {
				this.nextInterruptQuestion();
				this.ledWithInterrupt = true;
			} else {
				this.goToMainQuestion(0);
			}
		},

		restart() {
			this.resetState();
			this.setup();

			this.currentMainQuestion = 0;
			this.currentRecoveryQuestion = 0;
			this.currentInterruptQuestion = 0;
			this.didRepeatQuestion = false;
			this.didInterruptQuestion = false;
			this.currentLevel = 0;
			this.atEnd = false;
		},
	},
	provide() {
		return {
			parentView: false,
			showUUID: this.show_uuid,
		};
	},
};
</script>
