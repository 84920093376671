<template>
	<div
		:class="{
			'draggable-question': true,
			'is-double': hands.length <= 2,
			'is-quad': hands.length >= 4,
			'is-repeat': isRepeat,
		}"
	>
		<div
			ref="dropzone"
			:class="{
				'draggable-question__dropzone': true,
				'is-focused': handInRange,
			}"
		>
			<TimerShape
				:type="isRepeat ? 'cloud' : 'circle'"
				:duration="timer"
				:start="isActive"
				:delay="2"
				:paused="activeModals.length > 0 || isMobileLandscape"
				:animate="activeHand !== false"
				@expire="skipAnswer"
			/>

			<div class="draggable-question__help">
				<strong v-if="isRepeat">{{ $l10n('remember') }}</strong>
				<span v-content="$l10n('drag-help')" />
			</div>
		</div>

		<div class="draggable-question__hands">
			<BaseDraggableHand
				v-for="(hand, id) in hands"
				:key="id"
				v-bind="hand"
				:target="$refs.dropzone"
				:enabled="isActive && (activeHand === id || activeHand === false)"
				:margin="-0.3"
				:with-holster="useHolsters"
				@update="updateActiveHand"
				@drag="updateHandInRange"
				@drop="validateHandDrop"
			/>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';

export default {
	inheritAttrs: false,
	props: {
		hands: {
			type: Array,
			required: true,
		},
		timer: {
			type: Number,
			default: 0,
		},
		isActive: {
			type: Boolean,
			required: true,
		},
		isRepeat: Boolean,
		useHolsters: Boolean,
	},
	data() {
		return {
			activeHand: false,
			handInRange: false,
		};
	},
	computed: {
		...mapState(['activeModals', 'isMobileLandscape']),
	},
	methods: {
		skipAnswer() {
			this.activeHand = -1;
			this.$emit('select', 'timeout');
		},
		updateActiveHand(isDragging, hand) {
			if (isDragging) {
				this.activeHand = hand.$vnode.key;
			} else if (this.activeHand === hand.$vnode.key) {
				this.activeHand = false;
			}
		},
		updateHandInRange(inRange) {
			this.handInRange = inRange;
		},
		validateHandDrop(inRange, hand) {
			if (inRange) {
				const result = this.hands[hand.$vnode.key].value;
				this.$emit('select', result);
				this.$sounds.play('drop');
			} else {
				hand.reset();
			}
		},
	},
};
</script>
