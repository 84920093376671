var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
		'level-card': true,
		['is-level-' + _vm.number]: true,
		'is-unlocked': _vm.isUnlocked,
		'is-latest': _vm.isLatest,
	}},[_c('StarGraphic',{staticClass:"level-card__star",attrs:{"number":_vm.number}}),_c('div',{staticClass:"level-card__poster"},[_c('div',{staticClass:"level-card__poster__image",style:({
				'background-image': `url(${_vm.$url(_vm.imageUrl)})`,
			})})]),_c('div',{staticClass:"level-card__info"},[_c('h2',{staticClass:"level-card__title"},[_vm._v(_vm._s(_vm.name))]),(_vm.isUnlocked)?_c('BaseButton',{staticClass:"level-card__download",attrs:{"name":"download","href":_vm.$url(_vm.downloadUrl),"download":"","location":_vm.name,"is-primary":"","is-rounded":""}},[_vm._v(" "+_vm._s(_vm.$l10n('download'))+" "),_c('BaseIcon',{attrs:{"type":"download"}})],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }