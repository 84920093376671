export default {
	info: {
		size: 15,
		paths: {
			fill: 'M6.9,4.6c0,0.2,0.1,0.4,0.2,0.5c0.1,0.1,0.3,0.2,0.6,0.2S8,5.3,8.2,5.1C8.3,5,8.4,4.8,8.4,4.6c0-0.2-0.1-0.4-0.2-0.5C8,3.9,7.9,3.9,7.6,3.9S7.2,3.9,7.1,4.1C6.9,4.2,6.9,4.4,6.9,4.6z M7,6.4V11h1.3V6.4H7z M7.5,1C11.1,1,14,3.9,14,7.5S11.1,14,7.5,14S1,11.1,1,7.5S3.9,1,7.5,1 M7.5,0C3.4,0,0,3.4,0,7.5S3.4,15,7.5,15S15,11.6,15,7.5S11.6,0,7.5,0L7.5,0z',
		},
	},
	headphones: {
		size: 16,
		paths: {
			fill: 'M8,0.2c-4.4,0-8,3.6-8,8v4.1l0,0c0,2,1.6,3.5,3.6,3.5H4c0.4,0,0.7-0.3,0.7-0.7V9.4C4.8,9,4.4,8.6,4,8.6H3.6c-0.8,0-1.5,0.2-2.1,0.7V8.2c0-3.6,2.9-6.5,6.5-6.5c3.6,0,6.5,2.9,6.5,6.5v1.1c-0.6-0.4-1.3-0.7-2.1-0.7H12c-0.4,0-0.7,0.3-0.7,0.7V15c0,0.4,0.3,0.7,0.7,0.7h0.5c2,0,3.5-1.6,3.6-3.5l0,0V8.2C16,3.8,12.5,0.2,8,0.2C8,0.2,8,0.2,8,0.2z',
		},
	},
	gear: {
		size: 25,
		paths: {
			fill: 'M14.2,0.4c-1.1-0.2-2.3-0.2-3.4,0c-0.5,0.9-0.8,1.9-0.8,3C8.8,3.7,7.7,4.2,6.7,4.9c-0.8-0.6-1.8-1-2.8-1.2C3,4.5,2.3,5.4,1.7,6.4c0.4,1,1,1.8,1.8,2.5C3,10,2.8,11.2,2.7,12.5c-1,0.3-1.9,0.8-2.7,1.5c0.1,1.1,0.4,2.2,0.7,3.3c1,0.3,2.1,0.4,3.1,0.2c0.6,1.1,1.4,2.1,2.3,2.9c-0.4,1-0.6,2-0.5,3c0.9,0.6,2,1.1,3,1.5c0.9-0.6,1.6-1.4,2-2.3c1.2,0.2,2.5,0.2,3.7,0c0.5,0.9,1.2,1.7,2,2.3c1.1-0.3,2.1-0.8,3-1.5c0.1-1-0.1-2.1-0.5-3c0.9-0.8,1.7-1.8,2.3-2.9c1,0.2,2.1,0.2,3.1-0.2c0.4-1.1,0.6-2.2,0.7-3.3c-0.8-0.7-1.7-1.2-2.7-1.5c0-1.2-0.3-2.4-0.8-3.6c0.8-0.7,1.4-1.5,1.8-2.5c-0.6-1-1.3-1.9-2.1-2.6c-1,0.2-2,0.6-2.8,1.2c-1-0.7-2.1-1.3-3.3-1.6C15,2.3,14.7,1.3,14.2,0.4z M12.5,8.8c2.2,0,4,1.8,4,4c0,2.2-1.8,4-4,4c-2.2,0-4-1.8-4-4C8.5,10.6,10.3,8.8,12.5,8.8z',
		},
	},
	left: {
		width: 21,
		height: 24,
		paths: {
			fill: 'M1.5,14.6c-1.4-0.8-1.9-2.7-1.1-4.1C0.7,10,1,9.7,1.5,9.4l15-8.8c1.4-0.8,3.3-0.4,4.1,1.1c0.3,0.5,0.4,1,0.4,1.5v17.5c0,1.7-1.3,3-3,3c-0.5,0-1.1-0.1-1.5-0.4L1.5,14.6z',
		},
	},
	right: {
		width: 21,
		height: 24,
		paths: {
			fill: 'M19.5,9.4c1.4,0.8,1.9,2.7,1.1,4.1c-0.3,0.4-0.6,0.8-1.1,1.1l-15,8.8c-1.4,0.8-3.3,0.4-4.1-1.1c-0.3-0.5-0.4-1-0.4-1.5V3.2c0-1.7,1.3-3,3-3c0.5,0,1.1,0.1,1.5,0.4L19.5,9.4z',
		},
	},
	close: {
		size: 30,
		paths: {
			fill: 'M27.9,7.3c1.4-1.4,1.4-3.8,0-5.2c-1.4-1.4-3.8-1.4-5.2,0L15,9.8L7.5,2.3c-1.4-1.4-3.8-1.4-5.2,0c-1.4,1.4-1.4,3.8,0,5.2L9.8,15l-7.7,7.7c-1.4,1.4-1.4,3.8,0,5.2c1.4,1.4,3.8,1.4,5.2,0l7.7-7.7l7.5,7.5c1.4,1.4,3.8,1.4,5.2,0c1.4-1.4,1.4-3.8,0-5.2L20.2,15L27.9,7.3z',
		},
	},
	next: {
		size: 30,
		paths: {
			fill: 'M5.6,2.1c1-1.2,2.9-1.5,4.1-0.5l14.1,11.1c0.7,0.5,1.1,1.4,1.1,2.3s-0.4,1.7-1.1,2.3L9.8,28.4c-1.3,1-3.1,0.8-4.1-0.5c-1-1.2-0.8-3.1,0.5-4.1L17.4,15L6.1,6.1C4.8,5.2,4.6,3.3,5.6,2.1z',
		},
	},
	starSmall: {
		size: 12,
		paths: {
			fill: 'M5.8,1.3L7,3.7c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0,0.1,0l2.8,0.4c0.2,0,0.3,0.3,0.2,0.5l-2,1.9c0,0-0.1,0.1-0.1,0.1c0,0,0,0.1,0,0.1l0.5,2.7c0,0.2-0.2,0.4-0.4,0.3L5.6,8.6c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0L2.8,9.8C2.6,9.9,2.4,9.8,2.4,9.6l0.5-2.7c0,0,0-0.1,0-0.1c0,0,0-0.1-0.1-0.1l-2-1.9C0.6,4.6,0.7,4.3,0.9,4.3l2.8-0.4c0,0,0.1,0,0.1,0c0,0,0.1-0.1,0.1-0.1l1.3-2.4C5.3,1.1,5.7,1.1,5.8,1.3z',
			stroke:
				'M2.7,10.3c-0.2,0-0.3,0-0.4-0.1C2,10,1.9,9.7,2,9.5l0.5-2.6L0.5,5C0.3,4.9,0.2,4.6,0.3,4.3c0.1-0.3,0.3-0.5,0.6-0.5l2.8-0.4l1.2-2.4c0.2-0.5,1-0.5,1.3,0l1.2,2.4l2.8,0.4c0.3,0,0.5,0.2,0.6,0.5c0.1,0.3,0,0.5-0.2,0.7l-2,1.8L9,9.5c0,0.3-0.1,0.5-0.3,0.7c-0.2,0.2-0.5,0.2-0.8,0.1L5.5,9L3,10.2C2.9,10.3,2.8,10.3,2.7,10.3z M5.5,8.1c0.1,0,0.2,0,0.3,0.1l2.3,1.1L7.7,6.9c0-0.1,0-0.2,0-0.4c0-0.1,0.1-0.2,0.2-0.3l1.8-1.7L7.2,4.3C7.1,4.3,7,4.2,6.9,4.2C6.8,4.1,6.7,4,6.6,3.9L5.5,1.7L4.4,3.9C4.3,4,4.2,4.1,4.1,4.2C4,4.2,3.9,4.3,3.8,4.3L1.3,4.6l1.8,1.7c0.1,0.1,0.2,0.2,0.2,0.3s0,0.2,0,0.3L2.9,9.3l2.3-1.1C5.3,8.1,5.4,8.1,5.5,8.1z M2.5,6.8L2.5,6.8L2.5,6.8L2.5,6.8z M5.4,1.5L5.4,1.5C5.4,1.5,5.4,1.5,5.4,1.5L5.4,1.5z',
		},
	},
	starLarge: {
		size: 121,
		paths: {
			fill: 'M64.3,6.7l15,30.4c0.6,1.2,1.7,2,3,2.2l33.5,4.9c3.3,0.5,4.6,4.5,2.2,6.9L93.8,74.8c-1,0.9-1.4,2.3-1.2,3.6l5.7,33.4c0.6,3.3-2.9,5.8-5.8,4.3l-30-15.8c-1.2-0.6-2.6-0.6-3.8,0l-30,15.8c-3,1.6-6.4-1-5.8-4.3l5.7-33.4c0.2-1.3-0.2-2.6-1.2-3.6L3.2,51.1c-2.4-2.3-1.1-6.4,2.2-6.9L39,39.4c1.3-0.2,2.4-1,3-2.2L57,6.7C58.5,3.8,62.8,3.8,64.3,6.7L64.3,6.7z',
			stroke:
				'M94.4,118c-0.9,0-1.8-0.2-2.6-0.6l-30-15.8c-0.7-0.4-1.6-0.4-2.4,0l-30,15.8c-1.9,1-4.1,0.8-5.8-0.4c-1.7-1.2-2.6-3.3-2.2-5.4l5.7-33.4c0.1-0.8-0.1-1.7-0.7-2.2L2.2,52.2c-1.5-1.5-2.1-3.7-1.4-5.7c0.7-2,2.4-3.5,4.5-3.8l33.5-4.9c0.8-0.1,1.5-0.6,1.9-1.4l15-30.4c0.9-1.9,2.8-3.1,5-3.1c0,0,0,0,0,0c2.1,0,4,1.2,5,3.1l15,30.4c0.4,0.7,1.1,1.3,1.9,1.4l33.5,4.9c2.1,0.3,3.8,1.7,4.5,3.8c0.7,2,0.1,4.2-1.4,5.7L94.9,75.8c-0.6,0.6-0.9,1.4-0.7,2.2l5.7,33.4c0.4,2.1-0.5,4.2-2.2,5.4C96.7,117.6,95.6,118,94.4,118z M60.7,98.3c0.9,0,1.8,0.2,2.6,0.6l30,15.8c0.9,0.5,1.9,0.4,2.7-0.2c0.8-0.6,1.2-1.5,1-2.5l-5.7-33.4c-0.3-1.8,0.3-3.6,1.6-4.9L117.1,50c0.7-0.7,0.9-1.7,0.6-2.6s-1.1-1.6-2-1.7l-33.5-4.9c-1.8-0.3-3.4-1.4-4.2-3l-15-30.4C62.5,6.5,61.6,6,60.7,6c0,0,0,0,0,0c-1,0-1.8,0.5-2.3,1.4l-15,30.4c-0.8,1.6-2.4,2.8-4.2,3L5.7,45.7c-1,0.1-1.7,0.8-2,1.7c-0.3,0.9-0.1,1.9,0.6,2.6l24.3,23.7c1.3,1.3,1.9,3.1,1.6,4.9L24.4,112c-0.2,1,0.2,1.9,1,2.5c0.8,0.6,1.8,0.7,2.7,0.2l30-15.8C58.9,98.5,59.8,98.3,60.7,98.3z',
		},
	},
	download: {
		size: 15,
		paths: {
			fill: 'M7.4,11.1l4.8-4.5H8.8V1.9c0-1.4-2.9-1.4-2.9,0v4.7H2.6L7.4,11.1z M2,13.1c0-0.3,0.3-0.6,0.6-0.6h9.8c0.3,0,0.6,0.3,0.6,0.6s-0.3,0.6-0.6,0.6H2.6C2.2,13.7,2,13.4,2,13.1z',
		},
	},
	check: {
		width: 11,
		height: 9,
		paths: {
			fill: 'M4.1,8.6L0.4,4.8C0,4.4,0,3.8,0.4,3.4s1-0.4,1.4,0l2.3,2.3l5.1-5.1c0.4-0.4,1-0.4,1.4,0s0.4,1,0,1.4L4.1,8.6z',
		},
	},
	sound: {
		width: 29,
		height: 15,
		paths: {
			fill: 'M4.1,3H1.9C0.9,3,0,3.7,0,4.7v5.6c0,1,0.9,1.8,1.9,1.8h2.1V3z M13.6,0.4c-0.5-0.4-1.1-0.4-1.7-0.3L5.4,2.6v9.8l6.6,2.5c0.2,0.1,0.4,0.1,0.6,0.1c0.4,0,0.8-0.1,1.2-0.4c0.5-0.4,0.8-0.9,0.8-1.5V1.8C14.4,1.2,14.1,0.7,13.6,0.4z',
			on: 'M27.9,4.3c0.4,0.4,0.4,1,0,1.4l-6.5,6.1l-3.7-3.5c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l2.4,2.2l5.1-4.8C26.9,3.9,27.5,3.9,27.9,4.3z',
			off: 'M25.6,10.4c0.4,0.4,0.4,1,0,1.4c-0.2,0.2-0.5,0.3-0.7,0.3s-0.5-0.1-0.7-0.3l-2.4-2.4l-2.4,2.4c-0.2,0.2-0.5,0.3-0.7,0.3S18.2,12,18,11.8c-0.4-0.4-0.4-1,0-1.4l2.4-2.4L18,5.7c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l2.4,2.4l2.4-2.4c0.4-0.4,1-0.4,1.4,0s0.4,1,0,1.4l-2.4,2.4L25.6,10.4z',
		},
	},
	globe: {
		size: 16,
		paths: {
			fill: 'M14.6,12c0.7-1.2,1.1-2.6,1.1-4l0,0c0-1.4-0.4-2.8-1.1-4v0C12.4,0.3,7.6-0.8,4,1.4C2.9,2.1,2.1,2.9,1.4,4v0c-1.5,2.5-1.5,5.6,0,8.1v0c2.2,3.6,7,4.8,10.6,2.5C13.1,13.9,13.9,13.1,14.6,12L14.6,12z M9,14.4c-0.1,0.1-0.3,0.3-0.5,0.3c-0.3,0.1-0.7,0.1-1,0c-0.4-0.2-0.7-0.4-0.9-0.8c-0.5-0.7-0.8-1.4-1-2.2c0.8,0,1.6-0.1,2.4-0.1c0.8,0,1.6,0,2.4,0.1c-0.1,0.4-0.3,0.8-0.4,1.2C9.7,13.5,9.4,14,9,14.4L9,14.4z M1.2,8.4h3.1c0,0.9,0.1,1.7,0.3,2.6c-0.9,0.1-1.7,0.2-2.5,0.3C1.5,10.4,1.2,9.4,1.2,8.4L1.2,8.4z M2,4.7C2.9,4.8,3.7,4.9,4.6,5C4.4,5.8,4.3,6.7,4.3,7.6H1.2C1.2,6.5,1.5,5.6,2,4.7L2,4.7z M7,1.6c0.1-0.1,0.3-0.3,0.5-0.3c0.3-0.1,0.7-0.1,1,0C8.8,1.4,9.1,1.7,9.4,2c0.5,0.7,0.8,1.4,1,2.2C9.6,4.2,8.8,4.3,8,4.3c-0.8,0-1.6,0-2.4-0.1C5.8,3.8,5.9,3.4,6.1,3C6.3,2.5,6.6,2,7,1.6L7,1.6z M14.8,7.6h-3.1c0-0.9-0.1-1.7-0.3-2.6c0.9-0.1,1.7-0.2,2.5-0.3C14.5,5.6,14.8,6.5,14.8,7.6L14.8,7.6z M5.4,11c-0.2-0.8-0.3-1.7-0.3-2.5h5.7c0,0.8-0.1,1.7-0.3,2.5c-0.9-0.1-1.7-0.1-2.6-0.1C7.1,10.9,6.3,10.9,5.4,11L5.4,11z M10.6,5c0.2,0.8,0.3,1.7,0.3,2.5H5.1c0-0.8,0.1-1.7,0.3-2.5C6.3,5.1,7.1,5.1,8,5.1C8.9,5.1,9.7,5.1,10.6,5L10.6,5z M11.7,8.4h3.1c-0.1,1-0.4,2-0.8,2.9c-0.8-0.1-1.7-0.2-2.5-0.3C11.6,10.2,11.7,9.3,11.7,8.4L11.7,8.4z M13.5,3.9C12.7,4,12,4.1,11.2,4.1c-0.1-0.5-0.3-1-0.5-1.5c-0.2-0.4-0.4-0.8-0.7-1.2C11.4,1.9,12.6,2.7,13.5,3.9z M3.2,3.2C3.9,2.4,4.9,1.8,6,1.4l0,0.1C5.4,2.3,5,3.2,4.8,4.1C4,4.1,3.3,4,2.5,3.9C2.7,3.6,2.9,3.4,3.2,3.2z M2.5,12.1C3.3,12,4,11.9,4.8,11.9c0.1,0.5,0.3,1,0.5,1.5c0.2,0.4,0.4,0.8,0.7,1.2C4.6,14.1,3.4,13.3,2.5,12.1L2.5,12.1z M12.8,12.8c-0.8,0.8-1.8,1.4-2.8,1.7l0-0.1c0.6-0.8,1-1.7,1.2-2.6c0.7,0.1,1.5,0.1,2.2,0.3C13.3,12.4,13.1,12.6,12.8,12.8L12.8,12.8z',
		},
	},
	twitter: {
		size: 50,
		paths: {
			fill: 'M20.7,38.6c11,0,17.1-9.1,17.1-17.1c0-0.3,0-0.5,0-0.8c1.2-0.8,2.2-1.9,3-3.1c-1.1,0.5-2.3,0.8-3.4,0.9c1.3-0.7,2.2-1.9,2.6-3.3c-1.2,0.7-2.5,1.2-3.8,1.5c-1.1-1.2-2.7-1.9-4.4-1.9c-3.3,0-6,2.7-6,6c0,0.5,0.1,0.9,0.2,1.4c-4.8-0.2-9.3-2.5-12.4-6.3c-0.5,0.9-0.8,2-0.8,3c0,2,1,3.9,2.7,5c-1,0-1.9-0.3-2.7-0.8c0,0,0,0.1,0,0.1c0,2.9,2,5.3,4.8,5.9c-0.5,0.1-1,0.2-1.6,0.2c-0.4,0-0.8,0-1.1-0.1c0.8,2.4,3,4.1,5.6,4.2C18.3,35.1,15.7,36,13,36c-0.5,0-1,0-1.4-0.1C14.3,37.6,17.5,38.6,20.7,38.6z',
		},
	},
	facebook: {
		size: 50,
		paths: {
			fill: 'M35.1,32.2l1.1-7.2H29v-4.7c0-2,1-3.9,4.2-3.9h3.3v-6.2c-1.9-0.3-3.9-0.5-5.8-0.5c-5.9,0-9.8,3.5-9.8,9.7V25h-6.6v7.2h6.6L20.9,50H29L29,32.2L35.1,32.2L35.1,32.2z',
		},
	},
	deviceRotate: {
		size: 64,
		paths: {
			fill: 'M29.2,2.8c-2.5-2.7-6.6-2.8-9.3-0.3L2.4,19.9c-2.5,2.7-2.3,6.8,0.3,9.3l32,32c1.3,1.3,3.1,2.1,5,2.1c1.6,0,3.2-0.6,4.4-1.8l17.4-17.4c2.5-2.7,2.3-6.8-0.3-9.3L29.2,2.8z M59.5,42.1L42,59.5c-1.5,1.3-3.8,1.2-5.1-0.3l-32-32c-1.5-1.3-1.6-3.6-0.3-5.1L22,4.6c0.6-0.6,1.4-0.9,2.3-0.9c1.1,0,2.1,0.5,2.9,1.2l32,32C60.6,38.3,60.8,40.6,59.5,42.1z M46,46.1c-0.9,0.9-0.9,2.4,0,3.3c0.9,0.9,2.4,0.9,3.3,0s0.9-2.4,0-3.3c0,0,0,0,0,0C48.4,45.2,46.9,45.2,46,46.1C46,46.1,46,46.1,46,46.1z M63,20.4c-0.7-0.4-1.6-0.1-2,0.6l-0.4,0.8C59.2,10.4,50,1.5,38.5,0.5c-0.8-0.1-1.5,0.5-1.6,1.3s0.5,1.5,1.3,1.6l0,0c10.1,0.9,18.2,8.7,19.4,18.8L57,21.8c-0.6-0.5-1.6-0.4-2.1,0.2c-0.5,0.6-0.4,1.6,0.2,2.1l3.9,3.3h0c0,0,0,0,0.1,0c0.1,0,0.1,0.1,0.2,0.1c0,0,0,0,0,0s0,0,0,0c0.1,0,0.2,0.1,0.2,0.1c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.3,0h0c0.1,0,0.2,0,0.3,0l0.1,0c0.1,0,0.2,0,0.3-0.1l0,0c0.1,0,0.1-0.1,0.2-0.1c0,0,0,0,0,0c0.1-0.1,0.1-0.1,0.2-0.2c0,0,0,0,0,0s0,0,0,0c0-0.1,0.1-0.1,0.1-0.2c0,0,0,0,0,0v0l2.3-4.4C64,21.7,63.8,20.8,63,20.4C63,20.4,63,20.4,63,20.4z M25.8,60.5C15.6,59.7,7.5,51.8,6.3,41.7L7,42.2c0.6,0.5,1.6,0.4,2.1-0.2c0.5-0.6,0.4-1.6-0.2-2.1l-3.9-3.3h0c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1h0c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0.1c0,0-0.1,0-0.1,0c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0-0.1,0.1c-0.1,0-0.1,0.1-0.1,0.1c0,0,0,0-0.1,0.1l0,0c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1v0l-2.3,4.4c-0.4,0.7-0.1,1.6,0.6,2s1.6,0.1,2-0.6c0,0,0,0,0-0.1l0.4-0.8C4.8,53.6,14,62.5,25.5,63.5c0,0,0.1,0,0.1,0c0.8,0,1.5-0.7,1.5-1.5C27.1,61.2,26.5,60.6,25.8,60.5L25.8,60.5z',
		},
	},
	waves: {
		width: 10,
		height: 13,
		paths: {
			line1:
				'M1,0L1,0c0.6,0,1,0.4,1,1v11c0,0.6-0.4,1-1,1l0,0c-0.6,0-1-0.4-1-1V1C0,0.4,0.4,0,1,0z',
			line2:
				'M5,0L5,0c0.6,0,1,0.4,1,1v11c0,0.6-0.4,1-1,1l0,0c-0.6,0-1-0.4-1-1V1C4,0.4,4.4,0,5,0z',
			line3:
				'M9,0L9,0c0.6,0,1,0.4,1,1v11c0,0.6-0.4,1-1,1l0,0c-0.6,0-1-0.4-1-1V1C8,0.4,8.4,0,9,0z',
		},
	},
};
