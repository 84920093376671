<template functional>
	<svg :class="['emote', 'emote--' + props.face]" viewBox="0 0 80 80">
		<circle class="emote__backfill" fill="transparent" cx="40" cy="40" r="40" />
		<circle
			class="emote__fill"
			:fill="props.fill"
			stroke="#C12439"
			stroke-width="3"
			cx="40"
			cy="40"
			r="34"
		/>
		<g v-if="props.face === 'very-upset'" class="emote__face">
			<path
				fill="#C12439"
				d="M56,27.4c-3-0.2-5.8-1.9-8.2-4c-0.3-0.3-0.7-0.4-1.1-0.4c-0.4,0-0.8,0.2-1.1,0.4c-0.2,0.3-0.4,0.6-0.4,1
				c-0.1,0.2-0.1,0.4-0.1,0.6c0.2,2.4,0.8,4.7,1.6,6.9c0.7,1.9,1.5,4.3,3.1,5.6c0.8,0.8,2,1.2,3.1,1.1c1.1,0,2.2-0.5,3-1.4
				c1.6-1.5,1.9-3.8,2-5.9C57.7,29.9,57.3,28.3,56,27.4z"
			/>
			<path
				fill="#C12439"
				d="M35,27.7c0-0.4-0.1-0.8-0.4-1.1c-0.2-0.3-0.6-0.5-1-0.5c-0.4-0.1-0.8,0-1.1,0.3c-0.3,0.2-0.5,0.6-0.6,0.9
				c-0.8,1.6-1.9,3.1-3.3,4.3c-1.4,1.2-3,2.1-4.7,2.6L24,34.3c-0.1,0.1-0.2,0.2-0.3,0.3l-0.1,0.1l-0.1,0.2c-0.2,0.2-0.3,0.5-0.4,0.7
				c0,0.1-0.1,0.2-0.1,0.3c0,0.7,0,1.4,0,2.1c0,1.1,0.2,2.2,0.3,3.3c0.3,1.2,0.9,2.3,1.8,3.1c0.5,0.4,1,0.7,1.6,0.9
				c0.6,0.2,1.2,0.2,1.8,0.2c0.6-0.1,1.2-0.3,1.7-0.6c0.5-0.3,1-0.7,1.4-1.2c1.7-2,2.4-4.9,2.8-7.4C34.9,33.4,35.1,30.5,35,27.7z"
			/>
			<path
				fill="#C12439"
				d="M44.4,49.9c0.9,0,1.8,0.2,2.6,0.6c0.8,0.4,1.5,1,2,1.8c0.3,0.5,0.5,1.3,0,1.7c-0.1,0.1-0.3,0.2-0.4,0.2
				c-1.5,0.3-3-0.5-4.5-0.2c-1.5,0.2-2.1,1.8-3.4,2.2c-0.5,0.1-1,0-1.4-0.2c-0.4-0.2-0.7-0.6-0.9-1.1c-0.5-1.2,0.8-2.4,1.6-3.2
				C41.1,50.5,42.7,49.9,44.4,49.9z"
			/>
		</g>
		<g v-if="props.face === 'upset'" class="emote__face">
			<path
				fill="#C12439"
				d="M50.5,24c-0.5,1-0.9,2.1-1,3.2c-0.6,2.3-0.7,4.7-0.5,7.1c0.4,2.3,2,5.8,4.6,6.1c3.3,0.4,5.1-2.9,5.7-5.7
				c0.3-1.5,0.5-3,0.5-4.5c0-1.2-0.2-2.3-0.5-3.4C56.4,25.6,53.5,24.7,50.5,24z"
			/>
			<path
				fill="#C12439"
				d="M28.1,26.1c-1.3,0.7-2.7,1.3-4.1,1.9c-1.1,0.5-2.2,0.7-3.3,1.1c-0.3,0.1-0.5,0.2-0.8,0.3
				c-0.3,1.2-0.5,2.4-0.5,3.6c0,1.5,0.1,3,0.5,4.5c0.6,2.7,2.4,6.1,5.7,5.7c2.6-0.3,4.2-3.8,4.6-6.1c0.2-2.4,0.1-4.8-0.5-7.1
				C29.5,28.6,29,27.3,28.1,26.1z"
			/>
			<path
				fill="#C12439"
				d="M45.2,50.4c0.5,0.1,1,0.4,1.4,0.7c0.4,0.3,0.7,0.8,1,1.3c0.2,0.5,0.2,1.1,0,1.6c-0.2,0.5-0.5,0.9-1,1.2
				c-0.4,0.2-0.8,0.3-1.2,0.4c-2.1,0.4-7.3,0.7-7.3-2.4C38.1,50.3,43,49.7,45.2,50.4z"
			/>
			<path
				fill="#FFFFFF"
				d="M26.1,34.4c0-0.4,0-0.7,0.2-1c0.1-0.3,0.3-0.6,0.6-0.9c0.3-0.2,0.6-0.3,1-0.3c0.4,0,0.7,0.2,0.9,0.4
				c0.3,0.5,0.4,1,0.3,1.6c0,0.6,0,1.2-0.1,1.8c-0.1,0.3-0.2,0.6-0.4,0.8c-0.2,0.2-0.5,0.4-0.8,0.5C26.4,37.6,26.1,35.3,26.1,34.4z"
			/>
			<path
				fill="#FFFFFF"
				d="M60.6,30.6c-0.1-0.6-0.4-1.2-0.8-1.7c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.1-0.5,0
				c-0.2,0.1-0.4,0.4-0.5,0.7c-0.1,0.3-0.2,0.6-0.2,0.9c-0.2,0.7-0.2,1.4,0,2.1c0.1,0.3,0.2,0.7,0.4,0.9c0.1,0.1,0.3,0.2,0.4,0.3
				c0.2,0.1,0.3,0.1,0.5,0c0.5-0.1,0.7-0.6,0.9-1.1C60.6,31.9,60.7,31.2,60.6,30.6z"
			/>
		</g>
		<g v-if="props.face === 'neutral'" class="emote__face">
			<path
				fill="#C12439"
				d="M30.1,36.3c-0.1-2.2-0.6-4.4-1.4-6.5c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.1,0-0.2c-0.3-0.7-0.8-1.4-1.3-1.9
				c-0.6-0.5-1.3-0.9-2.1-1.1c-0.8-0.2-1.7-0.1-2.5,0.2s-1.5,0.7-2.1,1.4c-2.3,2.5-2,6.1-1.3,9.2c0.5,2.2,1.4,4.7,3,6.3
				c0.5,0.5,1.1,0.8,1.8,1c0.7,0.2,1.4,0.2,2,0.1c0.7-0.1,1.3-0.4,1.9-0.9c0.5-0.4,1-1,1.3-1.6C30,40.2,30.3,38.2,30.1,36.3z"
			/>
			<path
				fill="#C12439"
				d="M59.2,24.8c-0.6-0.8-1.4-1.3-2.3-1.6c-0.9-0.3-1.9-0.3-2.8,0c-2.1,0.8-2.6,3-2.7,5c-0.3,2.5-0.3,5-0.1,7.5
				c0.4,2.9,1.8,6,5.2,5.7c3.7-0.4,4.6-4.8,4.9-7.8C61.6,30.5,61.2,27.2,59.2,24.8z"
			/>
			<path
				fill="#C2233B"
				d="M37.2,56.8c4.3-1.3,8.8-2,13.4-2c0.4,0,0.8-0.2,1.1-0.4c0.3-0.3,0.4-0.7,0.4-1.1c0-0.4-0.2-0.8-0.4-1.1
				c-0.3-0.3-0.7-0.4-1.1-0.4c-4.8,0-9.6,0.7-14.2,2.1C34.5,54.5,35.4,57.4,37.2,56.8L37.2,56.8z"
			/>
			<path
				fill="#FFFFFF"
				d="M24.1,29.2c0-0.4,0-0.7,0.2-1c0.1-0.3,0.3-0.6,0.6-0.9c0.3-0.2,0.6-0.3,1-0.3c0.4,0,0.7,0.2,0.9,0.4
				c0.3,0.5,0.4,1,0.3,1.6c0,0.6,0,1.2-0.1,1.8c-0.1,0.3-0.2,0.6-0.4,0.8c-0.2,0.2-0.5,0.4-0.8,0.5C24.4,32.4,24.1,30.1,24.1,29.2z"
			/>
			<path
				fill="#FFFFFF"
				d="M58.6,25.4c-0.1-0.6-0.4-1.2-0.8-1.7c-0.1-0.1-0.3-0.2-0.5-0.3s-0.4-0.1-0.5,0c-0.2,0.1-0.4,0.4-0.5,0.7
				c-0.1,0.2-0.1,0.4-0.2,0.7c-0.3,0.8-0.3,1.6-0.1,2.4c0.1,0.3,0.2,0.7,0.4,0.9c0.1,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.4,0.1,0.5,0
				c0.5-0.1,0.7-0.6,0.9-1.1C58.6,26.7,58.7,26,58.6,25.4z"
			/>
		</g>
		<g v-if="props.face === 'happy'" class="emote__face">
			<path
				fill="#C12439"
				d="M29.1,35.2c-0.1-2.2-0.6-4.4-1.4-6.5c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.1,0-0.2c-0.3-0.7-0.8-1.4-1.3-1.9
		c-0.6-0.5-1.3-0.9-2.1-1.1c-0.8-0.2-1.7-0.1-2.5,0.2c-0.8,0.3-1.5,0.7-2.1,1.4c-2.3,2.5-2,6.1-1.3,9.2c0.3,1.3,0.8,2.8,1.4,4
		c1.7-0.4,3.9,0.1,5.1,1c0.7,0.5,1.3,1.1,1.8,1.8c0.2-0.1,0.3-0.2,0.4-0.3c0.5-0.4,1-1,1.3-1.6C29,39.2,29.3,37.2,29.1,35.2z"
			/>
			<path
				fill="#FFFFFF"
				d="M25.8,26.4c-0.2-0.3-0.6-0.4-0.9-0.4c-0.4,0-0.7,0.1-1,0.3c-0.3,0.2-0.5,0.5-0.6,0.9
		c-0.1,0.3-0.2,0.7-0.2,1c0,0.9,0.3,3.2,1.7,2.9c0.3-0.1,0.6-0.3,0.8-0.5c0.2-0.2,0.4-0.5,0.4-0.8c0.1-0.6,0.2-1.2,0.1-1.8
		C26.2,27.4,26,26.8,25.8,26.4z"
			/>
			<path
				fill="#C12439"
				d="M56.2,38.2c0.8-0.8,1.7-1.5,2.8-1.8c0.2-0.1,0.4-0.1,0.7-0.2c0.4-1.2,0.6-2.5,0.7-3.6
		c0.3-3-0.1-6.4-2.1-8.7c-0.6-0.8-1.4-1.3-2.3-1.6c-0.9-0.3-1.9-0.3-2.8,0c-2.1,0.8-2.6,3-2.7,5c-0.3,2.5-0.3,5-0.1,7.5
		c0.3,2.7,1.6,5.6,4.6,5.7C55.1,39.6,55.6,38.8,56.2,38.2z"
			/>
			<path
				fill="#FFFFFF"
				d="M57.6,24.4c-0.1-0.6-0.4-1.2-0.8-1.7c-0.1-0.1-0.3-0.2-0.5-0.3s-0.4-0.1-0.5,0c-0.2,0.1-0.4,0.4-0.5,0.7
		c-0.1,0.3-0.2,0.6-0.2,0.9c-0.2,0.7-0.2,1.4,0,2.1c0.1,0.3,0.2,0.7,0.4,0.9c0.1,0.1,0.3,0.2,0.4,0.3c0.2,0.1,0.3,0.1,0.5,0
		c0.5-0.1,0.7-0.6,0.9-1.1C57.6,25.7,57.7,25,57.6,24.4z"
			/>
			<path
				fill="#C12439"
				d="M43.8,51.6c0.7,0,1.4-0.2,1.9-0.6c0.5-0.3,1.2-0.4,1.8-0.3c0.6,0.1,1.2,0.4,1.6,0.9c0.4,0.5,0.7,1,0.9,1.6
		c0.2,0.6,0.2,1.2,0.1,1.8c-0.2,1.2-0.6,2.4-1.2,3.5c-0.5,1.1-1.2,2.1-2.1,3c-2.1,2-5.5,2.3-8.2,1.4c-1.7-0.5-3.2-1.6-4.2-3
		c-1.2-1.5-1.9-3.3-2.1-5.2c0-0.9,0.3-1.8,0.7-2.6c0.5-0.8,1.2-1.4,2-1.8c0.5-0.2,1-0.3,1.5-0.2c1.4,0.1,2.7,0.3,4,0.7
		C41.5,51.4,42.6,51.6,43.8,51.6z"
			/>
			<path
				fill="#FEC9FF"
				d="M44.9,58c1.5,1.6,1,3.4-1.8,3.6c-1.2,0.1-2.5-0.1-3.6-0.5c-1.1-0.5-2.2-1.2-2.9-2.2
		c-0.9-1.2-1.1-2.6,0.6-3.1c1.2-0.3,2.5-0.3,3.7,0s2.3,0.8,3.3,1.5C44.4,57.5,44.7,57.8,44.9,58z"
			/>
		</g>
		<g v-if="props.face === 'very-happy'" class="emote__face">
			<path
				fill="#C12439"
				d="M43.6,46.8c2.4,0.1,4.7-0.2,7-0.8c1.5-0.4,3-1,4.5-0.4c0.5,0.2,1,0.6,1.3,1.1c0.3,0.5,0.6,1,0.7,1.5
		c0.2,1.4,0.1,2.7-0.2,4.1c-0.2,1.6-0.5,3.1-1.1,4.6c-1.4,3.4-4.3,5.3-7.8,6c-2.9,0.6-5.9,0.2-8.7-0.9c-2.7-1.1-4.8-3.2-6-5.9
		c-0.9-2.5-2-5.9-0.5-8.3C34.5,44.7,40.8,46.7,43.6,46.8z"
			/>
			<path
				fill="#C12439"
				d="M53.4,26.8c-1.6,1.6-2.8,3.6-3.5,5.7c1.6-1.2,3.7-1.7,5.7-1.5c2.5,0.3,4.9,0.9,7.2,1.8
		c0.1-0.7-0.1-1.4-0.5-2c-0.4-0.6-1-1.1-1.6-1.3c-1.4-0.5-2.8-0.6-4.2-0.4c1.3-0.7,2.4-1.8,3.2-3.1c0.1-0.2,0.2-0.4,0.3-0.6
		c0-0.2,0.1-0.4,0-0.6C59.2,22.3,54.1,26.1,53.4,26.8z"
			/>
			<path
				fill="#C12439"
				d="M25.7,28.3c1,0.6,2,1.3,2.7,2.2c0.7,0.9,1.3,2,1.6,3.1c-2-0.7-4.1-0.8-6.1-0.5c-2.1,0.3-4,1.1-5.7,2.4
		c-0.1,0.1-0.2,0.1-0.3,0.1c-0.2,0-0.3-0.3-0.3-0.5c0-1.1,0.4-2.2,1.2-3.1c0.7-0.9,1.7-1.4,2.8-1.6c-1.5,0.2-6.7-0.8-3.5-2.4
		c1.2-0.5,2.5-0.8,3.8-0.7C23.2,27.3,24.5,27.7,25.7,28.3z"
			/>
			<path
				fill="#FEC9FF"
				d="M39.7,54.7c0.4-0.2,0.8-0.3,1.2-0.3c0.5-0.1,1-0.1,1.4,0c0.8,0.2,1.5,0.7,2.3,0.8c0.7,0.1,1.4,0,2.1-0.1
		c0.7-0.1,1.5,0,2.2,0.1c0.3,0,0.7,0.2,0.9,0.4c0.2,0.2,0.4,0.5,0.5,0.9c0.1,0.3,0,0.7-0.1,1c-0.3,0.6-0.8,1.1-1.4,1.4
		c-1.2,0.7-2.4,1-3.8,1.1c-1.3,0.1-2.6-0.1-3.9-0.5C39.9,58.8,37.9,55.8,39.7,54.7z"
			/>
		</g>
	</svg>
</template>

<script>
export default {
	props: {
		face: {
			type: String,
			required: true,
		},
	},
};
</script>
